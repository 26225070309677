<template>
  <b-row class="a-answer">
    <b-col class="answer-type-field mt-2" v-if="questionType == 'field'">
      <b-form-input disabled placeholder="Typ odpovede je vpisovateľné pole" />
    </b-col>
    <b-col class="" v-if="(questionType == 'choices' || questionType == 'multiselect')">
      <b-alert v-model="showDismissibleAlert" variant="danger" dismissible class="mt-4">
        {{ validationText }}
      </b-alert>
      <draggable :list="questionChoices" class="list-group" ghost-class="ghost" handle=".drag" @start="dragging = true" @end="dragging = false">
        <template v-for="(answer, indexA) in questionChoices">
          <b-row class="d-answer-item d-flex align-items-center py-1" :class="{ '--correct': answer.is_correct }" :key="indexA">
            <b-col cols="1">
              <div class="drag d-flex justify-content-start align-items-center border rounded">
                <b-link class="a-icon-wrapper --drag">
                  <b-icon icon="pause-fill" rotate="90" />
                </b-link>
                <strong>{{ indexA + 1 }}</strong>
              </div>
            </b-col>
            <b-col cols="11">
              <div class="d-flex align-items-center rounded border" :ref="'answerInput' + indexA">
                <b-form-input
                  v-model="answer.name" v-validate="{ required: true }"
                  :placeholder="'Odpoved ' + (indexA + 1)" :value="answer.name" :state="validateState('nameA' + (indexA + 1))"
                  :name="'nameA' + (indexA + 1)" class="border-0" debounce="100" autocomplete="off" type="search"
                  @keyup.enter="addNewAnswer(indexQ)"
                ></b-form-input>

                <div class="d-flex justify-content-end align-items-center pr-2">
                  <b-link class="a-icon-wrapper rounded-circle --check" :class="{ '--correct': answer.is_correct }" @click="isCorrect(indexA, indexQ)">
                    <b-icon icon="check-circle-fill" />
                  </b-link>
                  <b-link class="a-icon-wrapper ml-2 rounded-circle" @click="removeAnswer(indexA, indexQ)">
                    <img src="@/plugins/app/_theme/icon/delete.svg" />
                  </b-link>
                </div>
              </div>
            </b-col>
          </b-row>
        </template>
      </draggable>
      <div class="a-add-new-answer pt-3">
        <b-link class="d-inline-flex align-items-center w-color-primary" @click="addNewAnswer(indexQ)">
          <div class="a-icon-wrapper --plus">
            <img class="mr-2" src="@/plugins/app/_theme/icon/plus.svg" />
          </div>
          <span><strong>Pridať ďalšiu odpoveď</strong></span>
        </b-link>
      </div>
    </b-col>
  </b-row>
</template>

<script>
export default {

  components: {
    'draggable': () => import('vuedraggable'),
  },

  props: ['questions', 'questionType', 'questionChoices', 'index-q'],

  data() {
    return {
      showDismissibleAlert: false,
      validationText: '',
      answerText: '',
    }
  },

  computed: {
    isMultiselect() { 
      return this.questionType == 'multiselect'
    }
  },

  methods: {
    validateState(ref) {
      if (
          this.veeFields[ref] &&
          (this.veeFields[ref].dirty || this.veeFields[ref].validated)
      ) {
        return !this.veeErrors.has(ref)
      }
      return null
    },

    removeAnswer(a, i) {
      this.questions[i].choices.splice(a, 1)
    },

    async addNewAnswer(i) {
      const newIndex = this.questions[i].choices.push({ name: this.answerText, is_correct: false })
      await this.$nextTick()
      this.$refs['answerInput'+(newIndex-1)][0].querySelector('input').focus()
    },

    isCorrect(a, i) {
      if (!this.isMultiselect) {
        this.questions[i].choices.forEach((element) => {
          this.$set(element, 'is_correct', false)
        })
      }
      this.$set(
        this.questions[i].choices[a], 
        'is_correct', 
        this.isMultiselect ? !this.questions[i].choices[a].is_correct : true
      )
    }
  }
}
</script>

<style lang="sass" scoped>
.list-group
  .d-answer-item
    &.--correct
      background: #f5f4fa
      .border
        border-color: var(--a-color-secondary) !important
        border-width: 2px !important
    & > div
      padding-right: 0.25rem
      padding-left: 0.25rem
    & > div:first-child
      padding-left: 1rem
    & > div:last-child
      padding-right: 1rem
    .drag
      cursor: grab
    .border
      background: white
      height: 3rem
</style>